import { EventType } from '@repo/constants';
import { getUA, browserName, browserVersion, osName, osVersion, deviceType } from 'react-device-detect';
import { nanoid } from 'nanoid';

import { createEvent } from './api';

export function createTrackingIds() {
  if (localStorage.getItem('_did') === null) {
    localStorage.setItem('_did', nanoid());
  }

  if (sessionStorage.getItem('_sid') === null) {
    sessionStorage.setItem('_sid', nanoid());
  }
}

export function shouldTrackEvent(page, note, currentUserId, searchParams) {
  return (
    page
    && (
      !currentUserId
      || !(note?.creatorId && currentUserId === note.creatorId)
    )
    && searchParams.get('preview') === null
    && !page.noTracking
  );
}

export async function getIpAddressData() {
  // const response = await fetch('https://ipapi.co/json/');
  // return response.json();
  return { message: 'error #sorryblake' };
}

export async function getUserData() {
  let sessionId = sessionStorage.getItem('_sid');
  if (sessionId === null) {
    createTrackingIds();
    sessionId = sessionStorage.getItem('_sid');
  }
  const deviceId = localStorage.getItem('_did');

  const ipAddressData = await getIpAddressData();
  return {
    userDevice: {
      userAgent: getUA,
      browserName,
      browserVersion,
      osName,
      osVersion,
      deviceType,
    },
    userLocation: ipAddressData,
    sessionId,
    deviceId,
  };
}

export async function createPlayEvent(pageId, noteId, block, currentUserId = null) {
  const userData = await getUserData();
  return createEvent({
    eventType: EventType.VIDEO_PLAYED,
    resourceType: 'pages',
    resourceId: pageId,
    userId: currentUserId,
    metadata: {
      noteId,
      mediaId: block.data.mediaId,
      ...userData,
    },
  });
}

export async function createClickEvent(pageId, noteId, block, currentUserId = null, destination = null, text = null) {
  const userData = await getUserData();
  return createEvent({
    eventType: EventType.CTA_CLICKED,
    resourceType: 'pages',
    resourceId: pageId,
    userId: currentUserId,
    metadata: {
      noteId,
      text: text || block.data.text,
      url: destination || block.data.url,
      blockType: block.type,
      ...userData,
    },
  });
}

export async function createVcardDownloadEvent(pageId, noteId, block, currentUserId = null) {
  const userData = await getUserData();
  return createEvent({
    eventType: EventType.VCARD_DOWNLOADED,
    resourceType: 'pages',
    resourceId: pageId,
    userId: currentUserId,
    metadata: {
      noteId,
      vcardData: block.data,
      ...userData,
    },
  });
}

export async function createPageLoadEvent(page, currentUserId = null) {
  const userData = await getUserData();
  return createEvent({
    eventType: EventType.PAGE_VIEWED,
    resourceType: 'pages',
    resourceId: page.id,
    userId: currentUserId,
    metadata: {
      noteId: page.currentNote?.id || null,
      pageStatus: page.pageStatus,
      temporaryStatus: page.temporaryStatus,
      ...userData,
    },
  });
}
